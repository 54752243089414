import * as React from "react";

import { POLICIES } from "../constants/policies";

import "./policies.scss";

const PoliciesPage = () => {
  return (
    <div>
      <div className="main-content">
        <section className="left main-section">
          <div className="content-heading">
            <h2 id="policies-page" className="policies-heading">
              Policy Priorities
            </h2>
          </div>
          <section className="content">
            <div className="detailedPolicies">
              {POLICIES.map(({ name, description }, index) => {
                return (
                  <article id={name} key={index} className="article-padding">
                    <h4 className="policy-title">{name}</h4>
                    <div className="policy-description">{description}</div>
                  </article>
                );
              })}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default PoliciesPage;
