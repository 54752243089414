import * as React from "react";
import { Link } from "gatsby";

export const POLICIES = [
  {
    name: "Equity in Political Voice",
    description: (
      <div>
        <p>
          <b>
            Every other policy priority - and, in fact, every process in our
            city - depends on equitably balancing the needs of all residents.
          </b>{" "}
          Residents include not just those who show up to public meetings or who
          start petitions. They include busy parents, service workers, students,
          the unhoused, seniors, and others who simply don’t have the schedule,
          bandwidth, interest, or ability to initiate such participation.{" "}
        </p>
        <p>
          To be clear: Public comment and other such channels are absolutely
          valuable. I fully believe residents should be able to use these to
          express their views. But those who do are simply not representative of
          our entire population. A process fully reliant on such measures is the
          antithesis of equity.{" "}
          <b>
            It means the needs of our most time-strapped, politically
            inexperienced, and generally overburdened continually fall through
            the cracks of the system. Of course, included among these are our
            most desperately vulnerable.
          </b>
        </p>
        <p>
          This issue strikes at the core of what it means to have any semblance
          of a just community. It underpins every policy decision and government
          operation. There is nothing more pressing.
        </p>
        <p>
          <b>
            I intend to set aside some modest funding for a small internal
            research team.
          </b>{" "}
          Its mission will be investigating ways to improve processes and
          services in our city. Its first task will be finding methods to gain{" "}
          <b>
            timely, representative views into the true needs of residents for
            ongoing policy decisions of particular impact.
          </b>
        </p>
        <p>
          Minimal, yet strategic polling for such issues is one possibility.
          This might be done via phone or text. Such a strategy would put the
          onus on our government, and not on our busy populace, to initiate
          critical communication related to just those issues likely to deeply
          impact their lives.
        </p>
        <p>
          In any case, I know{" "}
          <b>
            there is a better way to incorporate crucial intel into our
            decisions.
          </b>{" "}
          We’ll never reach perfection, but improvement is valuable. This is my
          background and I know it can be done. If we really do want equity in
          our city, there is no more urgent investment.
        </p>

      </div>
    ),
  },
  {
    name: "Equity in Political Voice, Continued",
    description: (
      <div>
        <p>
          <b>We need to get rid of money in politics.</b> Political
          participation based on giving money to elected officials handicaps
          those of lesser means in pushing their priorities. Influence is
          instead concentrated among the wealthiest. The system also creates
          politicians whose time is spent calling up rich donors for hours on
          end and listening to their every thought and concern instead of
          constructing effective policy. Politicians, that is, who are
          inevitably more beholden to and who become more sympathetic with these
          backers.{" "}
        </p>
        <p>
          Consequently, it is the opposite of shocking that policies benefiting
          the wealthy are realized at the expense of those benefiting anyone
          else. This increases the wealth gap, further disadvantaging the less
          wealthy in their ability to influence.{" "}
          <b>
            The spiral ultimately results in crippling the ability for
            struggling people to have comparable political voice as well as deep
            neglect of their needs.
          </b>
        </p>
        <p>
          Let’s build a system that serves our people, not the highest bidder.
          Here in Cambridge, individual campaign contributions are capped at
          $1000 per person, per year. This is better than in many areas.
          However, we live in a reality where{" "}
          <a href="https://www.federalreserve.gov/publications/2021-economic-well-being-of-us-households-in-2020-dealing-with-unexpected-expenses.htm">
            more than one in three Americans would have trouble paying an
            unexpected $400 expense
          </a>
          , let alone a $1000 gift.
        </p>
        <p>
          <b>
            Also, there is no limit on what candidates themselves can contribute
            to their own campaigns.
          </b>{" "}
          Nothing prevents a billionaire from plowing as much as they want,
          without limit, into an election that directly enriches their bottom
          line.{" "}
          <a href="https://www.theguardian.com/commentisfree/2019/nov/09/us-democracy-politics-wealth-plutocracy-money">
            Amazon already tried to do this in Seattle
          </a>
          , injecting millions into a local city council election that would
          decide critical real estate priorities impacting their office
          headquarters.{" "}
        </p>
        <p>
          Something similar can happen here. And why wouldn’t it? Cambridge
          contains research headquarters for{" "}
          <a href="https://data.cambridgema.gov/Economic-Development/Life-Sciences-and-Technology-Company-Listing-Septe/fv53-bvhy/data">
            every multinational tech giant on the planet.
          </a>{" "}
          Not to mention our density of pharma giants. We must advocate to plug
          this loophole.
        </p>
        <p>
          No, money raised by a candidate does not, in itself, guarantee
          victory. There are other factors at work. Money is just one necessary,
          influential piece.{" "}
          <b>
            But it's not just about who wins elections; it's about who among us
            is able to run in the first place.
          </b>{" "}
          In my time as a candidate, I've witnessed several prospective fellow
          candidates hesitate or opt out of running because they felt they
          didn’t have the personal funds or monied connections to be a viable
          contender.
        </p>
        <p>
          This, in itself, is disgusting. There are few means for silencing an
          entire demographic more clearly disempowering than effectively barring
          some from holding public office. Or, at the very least, imposing{" "}
          <b>
            substantial, unnecessary, and wholly discriminatory added barriers.
          </b>{" "}
          If we ever feel disappointed by an unimpressive selection of
          personalities from whom we must choose our governors, councillors, and
          presidents, perhaps we should look at our system for getting elected.
          Our process filters out nearly all but heavily-backed partisans. That
          is simply reality.
        </p>
        <p>
          Cambridge should model a better way. There are limits to what local
          government can do, given unfortunate federal court decisions about
          money as political speech.{" "}
          <b>
            However, even if we cannot fully implement 100% public financing, we
            must do everything possible to restrict the impact of campaign
            donations.
          </b>{" "}
          Reducing maximum donations and implementing laws restricting donors
          from immediately profiting through work with the city after large
          donations are two measures that should be pursued.
        </p>
      </div>
    )
  },
  {
    name: "Housing Affordability",
    description: (
      <div>
        <p>
          Cambridge is a vibrantly diverse community of inventors, artists,
          students, front-line workers, teachers, business owners, and more. Our
          staggering occupational diversity fuels our creative powerhouse. <b>This
            vital diversity, however, can be drained when a booming local economy
            causes skyrocketing housing demand and prices.</b> Market forces drive out
          all but the wealthiest who can afford to live here, who are then left
          with privileged access to our economic opportunities and resources.
          Both upward and downward spirals result, worsening inequality.
        </p>
        <p>
          <b>This is about equity and inclusion. It’s also about the ability of our
            city to function as a creative and innovation powerhouse.</b> New ideas
          are a direct reflection of the community from which they are born. In
          a place like Cambridge where ideas have transformative and global
          impact every single day, inclusion in our city is akin to having a
          seat at the table in influencing ideas that may well shape our future.
          <b>The social and scientific innovations in our city are simply too
            important to take shape in a gilded bubble, detached from the
            realities of ordinary people.</b>
        </p>
        <p>
          It is vital that a diverse community defines and envelops our creative
          and innovation industries, since all must be at the table as we
          continue to grapple with who, exactly, benefits from the innovation
          that takes place here. This is a core question, not just for the
          future of Cambridge, but for us all.
        </p>
        <p>
          <b>For these reasons, we need to make deliberate investments in
            affordable housing options in every area of our city.</b> Policy
          mechanisms such as the Affordable Housing Overlay (AHO) and
          developments like the proposed 2072 Massachusetts Avenue affordable
          housing initiative are indispensable to realizing this vision. In
          addition we should also experiment with as many other promising policy
          mechanisms as possible to facilitate this end.
        </p>
        <dl>
          <dt>1. Abundant, Transit-Oriented Development</dt>
          <dd>
            <p>
              We need to support more housing in a way that encourages
              walkability, use of public transit, and minimal commutes. This
              enables the most efficient use of our very limited real estate and
              reduces traffic congestion. It also enables residents to better
              benefit from living and working here and facilitates a vibrant,
              healthy, sustainable, and accessible community.
            </p>
            <p>
              Consequently, I would like to{" "}
              <b>update zoning to allow small-scale multi-family housing</b>{" "}
              like triple-deckers, four-plexes, and six-plexes in Cambridge,
              which would help provide flexible living options for families. I
              would additionally like to{" "}
              <b>update height and setback requirements</b> for new housing from
              its legacy standards to accurately align with current building
              norms, enabling appropriate density. We should especially{" "}
              <b>focus development around major transit hubs</b> (subways, bus,
              and bike hubs) to encourage sustainable transportation.
            </p>
          </dd>

          <dt>2. Prioritizing Affordable Housing Development</dt>
          <dd>
            <p>
              I supported and continue to support the{" "}
              <b>Affordable Housing Overlay (AHO)</b>, which makes it easier to
              provide affordable living options citywide. I also support
              affordable housing initiatives like the proposed{" "}
              <b>2072 Massachusetts Avenue development.</b> I believe these
              enable continued accessibility of our city's rich opportunities to
              people of all walks of life, which speaks to our core values as a
              community. I believe we must additionally prioritize development
              of affordable housing options throughout our city through{" "}
              <b>
                publicly financed social housing on underutilized city-owned
                lots, increased inclusionary zoning, and homeownership programs.
              </b>
            </p>
          </dd>

          <dt>3. Addressing Homelessness</dt>
          <dd>
            <p>
              In among the wealthiest cities, states, and countries on earth,
              the state of our homeless community - especially during the
              pandemic - is unjustifiable.{" "}
              <b>
                I support a housing first approach embodied by the{" "}
                <a href="https://www.righttohousing.com/the-project">
                  Right to Shelter initiative
                </a>
              </b>{" "}
              that has already made significant progress in providing unused,
              non-congregate space to residents in need supported by federal
              grants.
            </p>
          </dd>

          <dt>4. Experimenting with Land Trusts</dt>
          <dd>
            <p>
              One approach to enabling more stable, affordable options in
              volatile housing markets is to separate the speculative value of
              land from the actual price of a house.{" "}
              <b>
                The City of Cambridge can allocate public land for use by groups
                of individuals to administer as land trusts,
              </b>{" "}
              where houses are rented out but the land itself is owned by these
              groups. Since the property’s price no longer includes the land, it
              can be sold or rented separately at considerably cheaper rates
              than on the open market.
            </p>
          </dd>

          <dt>5. Experimenting with Social Ownership/Limited-Equity Coops</dt>
          <dd>
            <p>
              Social ownership involves long-lasting organizations with an
              emphasis on{" "}
              <b>
                cultivating social and community benefits rather than centering
                profit.
              </b>{" "}
              Such programs deserve our consideration and should be supported by
              the city.
            </p>
          </dd>

          <dt>6. Student Housing</dt>
          <dd>
            <p>
              <b>
                The city should negotiate with local universities to ensure they
                provide adequate housing
              </b>{" "}
              for undergraduate and graduate students, which will alleviate
              pressure on the housing market.
            </p>
          </dd>

          <dt>7. Support for Tenants</dt>
          <dd>
            <p>
              I support ensuring every tenant has{" "}
              <b>affordable access to legal counsel</b> in housing court, which
              balances the field in negotiations with landlords during and
              outside of eviction cases. I would also like the city to explore
              investing in <b>affordable mediation services.</b> An efficient,{" "}
              <b>
                easier-to-navigate application system for affordable housing,
                flexible loan options for those of low income, financial
                planning support, and accessible home ownership programs
              </b>{" "}
              would also benefit tenants. I also support creating a{" "}
              <b>public database of the rental market,</b> which will facilitate
              transparency for both tenants and landlords.
            </p>
          </dd>

          <dt>8. Compensation for Board and Commission Positions</dt>
          <dd>
            <p>
              Right now planning boards and related groups are
              disproportionately wealthier, older, male, and longtime resident
              homeowners. This nonrepresentative background likely biases
              decisions related to new housing development in ways that do not
              reflect the overall preferences of the community.{" "}
              <b>
                I support converting participation on such boards and
                commissions into paid positions
              </b>{" "}
              to enable those of lesser means to participate, thereby adding
              diversity of perspective to these bodies.
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Sustainable Transit",
    description: (
      <div>
        <p>
          High quality transit is critical for a functional, sustainable, and
          accessible city. We must actively invest in our growing transit
          infrastructure to encourage use and ensure safety.
        </p>
        <dl>
          <dt>1. Protected Bike Lanes</dt>
          <dd>
            <p>
              As a supporter of the{" "}
              <b>
                <a href="https://www.cambridgema.gov/streetsandtransportation/policiesordinancesandplans/cyclingsafetyordinance">
                  Cycling Safety Ordinance,
                </a>
              </b>{" "}
              I support investing in a connected, balanced network of protected
              bike lanes. This ensures the safety of our{" "}
              <a href="https://data.eco-counter.com/public2/?id=100023038">
                many daily bikers
              </a>{" "}
              whose numbers{" "}
              <a href="https://cityofcambridge.shinyapps.io/CitywideBicycleCounts/">
                grow by roughly 8% per year,
              </a>{" "}
              not to mention that of drivers and pedestrians.
            </p>
            <p>
              As a non-car owner who relies on biking and walking as my primary
              means of commuting, shopping, and general transportation, I
              experience the dangers of our current infrastructure everyday
              firsthand. Creating strong and safe bike infrastructure will help
              our city reach its health, environment, and livability goals.
            </p>
          </dd>

          <dt>2. Encouraging Transit Use</dt>
          <dd>
            <p>
              Greater investment in busing, subway access, and walkability in
              addition to safe bikeways is critical.{" "}
              <b>Additional city-provided busing in areas not well served</b> as
              well as reducing barriers to using transit generally (for example,{" "}
              <b>providing discounts and vouchers</b>) can also contribute here.
            </p>
          </dd>

          <dt>3. Updating Blanket Parking Minimums</dt>
          <dd>
            <p>
              Cambridge’s scarce real estate means we must carefully balance
              competing community needs including housing, public resources,
              business requirements, and infrastructure. I support updating
              current blanket requirements for off-street parking for every new
              residential and commercial development.{" "}
              <b>
                Unneeded parking provision results in waste of valuable real
                estate
              </b>{" "}
              and building cost and results in yet more wasteful urban sprawl.{" "}
              <b>
                However, we must take into account the needs of small
                businesses, who rely on accessible parking for visitors, as well
                as residents reliant on street parking.
              </b>{" "}
            </p>
            <p>
              <b>
                I propose investing in reasonable third-party studies on
                appropriate parking needs by microdistrict
              </b>{" "}
              based on current use of on-street parking, businesses, transit
              access, most likely demographics of new occupants, and other
              factors. This would help the city understand real parking needs at
              an appropriate scale, better serve our residents, and eliminate
              wasted space. Existing requirements do not do this, and I believe
              we can do better.{" "}
            </p>
          </dd>

          <dt>4. Municipal Sidewalk Shoveling Program</dt>
          <dd>
            <p>
              <b>Snow removal is a matter of pedestrian safety</b> and it's
              everyone's responsibility to share the load.{" "}
              <b>Making snow removal a city service</b> makes sense from the
              point of view of doing exactly that, from the taxpayer
              perspective. But it also has many logistical advantages: If one
              service takes responsibility for city-wide implementation, it can
              benefit from economies of scale and perform snow removal with much
              greater efficiency. Not to mention the nuisance for many residents
              who are out of down during blizzards who have to (1) realize there
              has been a blizzard in Cambridge and (2) independently arrange a
              service to perform snow removal for just their property. The
              amount of saved time, energy, and complication absolutely
              justifies such a program and would undoubtedly result in cheaper,
              more reliable execution and safer streets for everyone.
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Investing in our Youth",
    description: (
      <div>
        <p>
          Our city is in need of programs that serve our young adults. The
          upsurge in gun violence is one symptom of this failure.
        </p>
        <dl>
          <dt>1. Investigating Causes</dt>
          <dd>
            <p>
              First and foremost, we need deliberate and targeted{" "}
              <b>
                investigation of the causes of our recent upsurge in youth
                violence.
              </b>{" "}
              This can inform potential programs that provide safe, supportive
              environments for young adults.
            </p>
          </dd>

          <dt>2. Programming</dt>
          <dd>
            <p>
              <b>
                Additional programming for workforce and skills training as well
                as internships with local employers
              </b>{" "}
              can tie our youth into their local community, hone marketable
              skills, and forge mentorship relationships. I also support
              otherwise{" "}
              <b>
                encouraging our companies and businesses to formally hire more
                local talent.
              </b>
            </p>
            <p>
              <b>
                Competitions and awards in an unprecedented variety of domains,
                sponsored by local businesses,
              </b>{" "}
              are another way to encourage participatory learning, job
              marketability, self-esteem, and active engagement in the
              community. Inspired by the{" "}
              <a href="https://www.congressionalappchallenge.us/">
                Congressional App Challenge
              </a>
              , which invites middle and high school students to submit coding
              projects, the city can organize themed challenges that encourage
              our youth to submit projects in art, journalism, technology,
              science, cuisine, or other categories to be judged by local
              professionals in the field. Winners of these challenges can be
              publicly honored and all participants can be showcased, gaining
              them visibility to potential employers and mentors and inspiring
              others to become involved in such events.
            </p>
            <p>
              <b>
                Starting a new tradition of short creative performances by local
                public school students to begin each city council meeting
              </b>{" "}
              is another way, not just to inspire and enliven our public
              meetings with local talent, but to engage more youth and families
              in community discussion.
            </p>
            <p>
              <b>
                Other programs to get students involved in public creative and
                service projects like community art,
              </b>{" "}
              urban agriculture, and neighbor assistance can also help further
              community engagement.
            </p>
          </dd>

          <dt>3. Universal Pre-K</dt>
          <dd>
            <p>
              Although formally within the purview of the school committee,{" "}
              <b>
                I support provision of universal access to pre-kindergarten,
              </b>{" "}
              which puts kids on a path upward from the start and facilitates
              equitable educational opportunity.
            </p>
          </dd>

          <dt>4. Culture and Language Accommodation</dt>
          <dd>
            <p>
              Also formally within the purview of the school committee,{" "}
              <b>
                I support more comprehensive efforts to translate important
                parental communications to the primary languages of student
                families,
              </b>{" "}
              as well as any additional culturally tailored outreach, to ensure
              informed familial educational involvement.
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Addressing Climate Change and Green Space",
    description: (
      <div>
        <p>
          Climate change is an existential crisis. Even at the local level, our
          city can take numerous steps to enact sustainable initiatives that
          model what it means to do our part.
        </p>
        <dl>
          <dt>1. Net-Zero Carbon Emissions</dt>
          <dd>
            <p>
              I support moving the city toward net-zero carbon emissions through
              taxing existing emissions from major contributors like large
              commercial developments as part of a local{" "}
              <a href="https://www.danaforcambridge.com/gndPledge">
                Green New Deal.
              </a>
            </p>
          </dd>

          <dt>2. Tree Canopy, Permeable Surfaces</dt>
          <dd>
            <p>
              I support maintaining and growing our tree canopy according to the{" "}
              <a href="https://www.cambridgema.gov/Departments/PublicWorks/Initiatives/UrbanForestMasterPlan">
                Urban Forest Master
              </a>{" "}
              Plan to restore our much needed tree cover. I also support
              incentivizing permeable surfaces wherever feasible for driveways,
              parks, and other land areas.
            </p>
          </dd>

          <dt>
            3. Public-Private Partnerships for Local Environmental Innovation
          </dt>
          <dd>
            <p>
              As a vibrant hub of innovation, Cambridge also has the opportunity
              to <b>partner with local research and innovation institutions</b>{" "}
              to explore creative new ideas and sustainable technologies in{" "}
              <b>
                urban agriculture, energy use, and infrastructure monitoring.
              </b>
            </p>
          </dd>

          <dt>4. Green Space</dt>
          <dd>
            <p>
              Green Space is critical for mental and social well being,
              especially during our current pandemic. I support{" "}
              <b>prioritizing areas for recreation and play.</b> I also support
              the equitable provision of <b>dog parks</b> for our nonhuman
              residents.
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Charter Reform",
    description: (
      <div>
        <p>
          Our city government has a unique structure called Plan E, which places
          the bulk of authority with our city manager, who is appointed by the
          council. While councillors are responsible for voting on policy
          changes, the city manager runs day-to-day government operations and
          most internal hiring.
        </p>
        <p>
          <b>
            The amount of concentrated power placed in our unelected manager has
            caused problems.
          </b>{" "}
          The proposed feasibility study for municipal broadband, which would
          have cheaply clarified the costs and benefits of implementing public
          city-wide internet, is an example. After a vote in favor by the
          council the study was blocked from moving forward by the city manager.
        </p>
        <p>
          Technically, the council can fire the city manager at any time with a
          majority vote; in practice, however, this is no small decision.
          Leaving the city without a manager can cause immediate logistical
          crises in the basic functioning of the city, especially if there is no
          named deputy city manager to smoothly fill in (as there isn't now).
        </p>
        <p>
          To be clear, I take issue with the structure of our government, not
          any individual.{" "}
          <b>
            We need a better system of accountability among our unelected
            officials that does not radically threaten the basic functioning of
            all city departments to operate.
          </b>{" "}
          This issue extends well beyond the topic of municipal broadband, which
          simply illustrates the deeper challenges. We cannot allow the wishes
          of the public and their representatives to be able to be arbitrarily
          blocked. That is not a democratic process.
        </p>
        <p>
          <b>
            The recently proposed measures of required board appointment
            approval by the council as well as yearly reviews of the city
            manager are a start to getting at implementing more accountability,
            though I believe more is needed.
          </b>{" "}
          At the very least, I believe real, meaningful, and practicable
          consequences for noncompliance by unelected officials are necessary.
          These might include{" "}
          <b>
            legally enforced, predefined timelines for acting upon mandates by
            the council,
          </b>{" "}
          for example. Switching to a system with an elected executive official
          is something we should alternatively consider.
        </p>
        <p>
          I want the form of government that best serves our people.{" "}
          <b>
            There are advantages to our "strong manager / weak mayor" system:
          </b>{" "}
          Operations tend to be more efficient, since they are headed by someone
          who had to submit a resume detailing their relevant professional
          qualifications to run the operations of a city and judged on that
          basis, rather than a person tasked with being charismatic, rousing,
          and having wide popular appeal. And the theory goes that in a “strong
          manager / weak mayor” government like our own, operations, hiring
          processes, and most appointments are “depoliticized,” since they are
          led by a government employee who is not directly elected by the
          people.
        </p>
        <p>
          However, I feel that, in reality, there is not one role in our
          government that is not political. There is not one role in life that
          is not political. Every person has an agenda, and if they are not
          directly beholden to voters then they are beholden to their own
          individual biases and political leanings.{" "}
          <b>
            Concentrating undue power within a single human being is always an
            extremely risky and undemocratic structure.
          </b>{" "}
          It is an acute liability to the stability of our city and to the
          wellbeing of our people.
        </p>
        <p>
          <b>
            Thus, I feel if we are to keep our current Plan E structure, we must
            enact greater measures of true accountability and a more balanced
            distribution of oversight as mentioned above. And switching to an
            elected mayor system is something we should consider.
          </b>
        </p>
      </div>
    ),
  },
  {
    name: "Choosing a Next City Manager",
    description: (
      <div>
        <p>
          Regardless of our decisions on charter reform, our current city
          manager is retiring this coming July 5. Until such time as we may or
          may not update our Plan E Charter, the council must choose a next city
          manager.{" "}
          <b>
            As applicable, I will help choose a city manager who prioritizes
            transparency, inclusion, and community engagement and who is willing
            to collaborate closely with the council on forward-thinking
            initiatives.
          </b>
        </p>
      </div>
    ),
  },
  {
    name: "Local Innovation for Community Benefit",
    description: (
      <div>
        <p>
          Cambridge is a powerhouse of research, industry, and world-changing
          material innovation. We are home to world-renowned institutions like
          MIT and Harvard, a nearby hotbed of small but visionary startups
          dubbed the{" "}
          <a href="https://sponsored.bostonglobe.com/pmi/kendall-square-history/">
            “most innovative square mile on earth,”
          </a>{" "}
          a global biotech hub, and the{" "}
          <a href="https://data.cambridgema.gov/Economic-Development/Life-Sciences-and-Technology-Company-Listing-Septe/fv53-bvhy/data">
            innovation labs for every tech giant in existence.
          </a>{" "}
          As a birthplace of the early internet and developments ranging from
          residential solar power to the Moderna vaccine, Cambridge originates
          ideas that radiate around the globe.{" "}
          <b>
            I believe we should leverage the dynamism of our local innovation
            community in strategic partnerships for public benefit.
          </b>
        </p>
        <dl>
          <dt>1. Innovation Lab</dt>
          <dd>
            <p>
              As mentioned earlier, I believe we should set aside modest funding
              for a small research and innovation team within Cambridge
              government. In addition to investigating ways to clarify public
              priorities, the team should experiment with and facilitate local
              partnerships enabling{" "}
              <b>
                exploratory pilot initiatives in areas like smart
                infrastructure, new green technologies, efficient mobility, and
                more.
              </b>{" "}
              This might be similar to Boston’s{" "}
              <a href="https://www.boston.gov/departments/new-urban-mechanics">
                Office of New Urban Mechanics.
              </a>{" "}
              Such cross-organizational partnerships have already proved
              valuable in initiatives like{" "}
              <a href="https://www.bostonglobe.com/2021/04/15/business/cambridge-begin-guaranteed-income-program-poor-families/">
                Cambridge RISE,
              </a>{" "}
              our universal basic income-inspired local pilot.
            </p>
            <p>
              We have so much innovative talent here in Cambridge. I believe
              there is untapped potential in leveraging this for exciting public
              initiatives that can model what is possible in other cities.
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Support During the COVID-19 Pandemic",
    description: (
      <div>
        <p>
          These past two years have been an unprecedented struggle for
          residents, families, and the local businesses that form the pillars of
          our community.
        </p>
        <dl>
          <dt>1. Grants and Relief Funds</dt>
          <dd>
            <p>
              Our city should continue to{" "}
              <b>
                expand its support of local residents and businesses through
                grants and relief funds to ensure that doors stay open.
              </b>{" "}
              We must also ensure that such recovery efforts are equitable and
              that none of Cambridge’s residents are left behind.{" "}
              <b>
                The city should also continue to support and promote outdoor
                patios for dining during warm weather.
              </b>
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Expanding and Reforming Voting",
    description: (
      <div>
        <p>
          I support advocating for the provision of <b>24-hour voting,</b> which
          involves instituting a 24-hour period during which residents with less
          typical working hours (e.g., service workers, artists, etc.) can vote
          in person in addition to existing regular and early voting options.
          The city should also advocate for{" "}
          <b>
            automatic and/or same-day voter registration as well as extend no
            excuse vote-by-mail as an option indefinitely.
          </b>
        </p>
        <dl>
          <dt>1. Five Star Voting</dt>
          <dd>
            <p>
              Cambridge’s ranked-choice voting (RCV) system is a substantial
              improvement over traditional voting. Nevertheless, the process of
              determining who actually wins in RCV is{" "}
              <a href="http://rwinters.com/elections/supplement.htm">
                generally unintelligible to the average voter
              </a>{" "}
              as well as bizarre in that it incorporates an element of random
              chance.{" "}
              <b>
                This means that a vote recount of a perfectly valid RCV election
                result can produce a different electoral outcome in a way that
                is equally valid.
              </b>{" "}
              So, although RCV certainly surpasses traditional voting, it
              doesn’t score points for transparency or accessibility to average
              people nor to public confidence in the final election result.
            </p>
            <p>
              <b>I propose advocating for “five star voting,”</b> which involves
              simply rating each candidate out of five stars (with unrated
              candidates getting a default 2.5 stars). This way, we are free
              from worrying about similar candidates “splitting the vote” and
              have the benefit of a system whose result is both{" "}
              <b>not subject to chance and easy to understand:</b> Winners are
              simply determined by taking average ratings and selecting the
              highest.
            </p>
            <p>
              A side benefit of this system is that elections could reveal more
              nuance in voter preferences. For example, we could see which
              candidates are most polarizing (rated most often either one or
              five stars) or most frequently unrated. This is actually of great
              public value, as it{" "}
              <b>
                enables our government to better understand the revealed
                political and policy preferences of our populace
              </b>{" "}
              and perhaps better respond to underlying needs.
            </p>
            <p>
              I am not aware of anywhere that uses this system or that has
              proposed the concept before.{" "}
              <b>
                In embracing our role as a forward-thinking city, however, I see
                no reason why Cambridge should not be first
              </b>{" "}
              to test it, perhaps initially via informal polling. Perhaps we can
              inspire other governments - most interestingly, those dominated by
              our two major political parties - in which voting honestly for an
              independent candidate will no longer be a vote thrown away.
            </p>
            <p>
              This advantage - also enjoyed by the RCV system - is impactful. In
              governments dominated by two major parties,{" "}
              <b>
                opening the doors for third party candidates dismantles immense
                concentrations of power
              </b>{" "}
              among party leaders. Currently, these leaders often dictate how
              all other members of their party vote, and, more often than not,
              they fall in line. Power is condensed into the hands of a few in a
              way antithetical to how the system should work, resulting in
              increasing partisan division and the conversion of many elected
              officials into sheep. We need more third party officials not
              beholden to such structures who are free to vote in line with
              their true beliefs. Only in this way can we enable democracy to
              function properly.
            </p>
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Municipal Broadband",
    description: (
      <div>
        <p>
          <b>
            We need to perform a feasibility study to illuminate the costs and
            benefits of implementing city-wide public internet.
          </b>{" "}
          The study on municipal broadband feasibility was blocked by the city
          manager against the vote by the city council, representing an example
          of our need for more accountability among unelected officials in
          respecting the wishes of the public and their chosen representatives.
        </p>
        <p>
          Regardless of your views on whether we should, in fact, have municipal
          broadband, opposing a relatively cheap feasibility study of its costs
          and benefits is simply unjustifiable. In fact, it is nothing less than
          pulling the wool over the eyes of our people regarding the real facts.
          It also obscures and stagnates further discussion about the right
          strategy for providing equitable digital access to our people.{" "}
          <b>
            This is an incredibly important issue - one that touches upon
            fundamental human rights to basic utilities that enable, among other
            things, access to critical health information, means to financial
            income, and access to education.
          </b>
        </p>
        <p>
          Comcast's internet monopoly in the area is damaging. Fundamentally, I
          see this within the context of{" "}
          <b>
            our city’s urgent responsibility to ensure every Cambridge resident
            has affordable access to the internet.
          </b>{" "}
          Frankly, I am agnostic as to the specific means by which we do this -
          whether through municipal broadband, investing in competition,
          subsidizing access, or otherwise. But I do believe that halting
          further study on the matter does nothing to benefit anyone.
        </p>
      </div>
    ),
  },
  {
    name: "Investing in the Arts",
    description: (
      <div>
        <p>
          The arts are core to the cultural richness of our community. I support
          the continuation of our public art initiatives, especially creative
          programs that{" "}
          <b>
            merge the arts with cultural events, community building, climate
            preparedness, and city beautification.
          </b>
        </p>
        <p>
          <b>Local art shows in the CambridgeSide Galleria Mall</b> during the
          winter, for example, located within a bright, lush, heated indoor
          garden, would be a burst of fresh air to get us through these dark
          months while nourishing the soul. Themed events for each holiday that
          partner with local businesses and accompany larger community events
          could also be a great way to engage the community.
        </p>
        <p>
          <b>
            I fully support continuing investment in recovery efforts within the
            arts sector
          </b>{" "}
          toward pre-pandemic wellbeing as well as ensuring the arts are
          actively included in long-term economic planning.
        </p>
      </div>
    ),
  },
  {
    name: "Public Safety",
    description: (
      <div>
        <p>
          The function of public safety should involve an ongoing process of
          earned trust among residents, collective buy-in, and collaborative
          solution seeking.{" "}
          <b>
            I support many of the ideas expressed in the recently proposed HEART
            Proposal related to delegating certain mental health emergency calls
            to social workers,
          </b>{" "}
          thereby applying specialized expertise in this area and simultaneously
          enabling our police force to better focus on true public threats. I
          believe that, through ongoing conversations among citizen activists
          and city officials, we can effectively integrate these ideas into a
          community-driven model for safety in Cambridge.
        </p>
        <p>
          In addition to these formal efforts,{" "}
          <b>
            a safe community relies on the social connectedness of its
            neighborhoods.
          </b>{" "}
          Neighbors who look after each other are neighbors{" "}
          <a href="https://link.springer.com/article/10.17269/s41997-020-00309-2">
            significantly less vulnerable to a variety of dangers.
          </a>{" "}
          I propose provisioning a community events liaison for each
          neighborhood responsible for exploring how best to catalyze organic,
          ongoing hyper local community events. The output of this exploration
          may be understanding the need to raise modest funding for food or
          venue, or to perhaps help facilitate outreach. Either way, I believe
          communities generally want to organize such events, and this resource
          can relieve any logistical hurdles.
        </p>
      </div>
    ),
  },
  {
    name: "Infrastructure",
    description: (
      <div>
        <p>
          We must invest in safe, robust, and sustainable water, gas, and
          utility infrastructure. In doing so, we must also respect the ongoing
          quality of life for our community.{" "}
          <b>
            I support updating our guidelines for noisy roadside construction
            from a 7:00am to a 9:00am start time for the sanity of all
            residents.
          </b>
        </p>
      </div>
    ),
  },
  {
    name: "In Closing",
    description: (
      <div>
        <p>
          <b>
            Cambridge is a powerhouse of material innovation. We should be a
            trailblazer in social innovation as well.
          </b>{" "}
          As we grapple with increasing inequality, public safety, and climate
          change, we must invest in all our people and in the fundamental
          processes and services that matter.
        </p>
        <p>
          Our challenges are substantial. Yet we also benefit from nearly{" "}
          <b>
            unparalleled and untapped diversity of ideas right within our
            borders and the local resources to achieve real improvement.{" "}
          </b>
        </p>
        <p>
          Cities are sometimes considered “laboratories of democracy,” since
          they are places where ideas are generated, tried, and evaluated. Often
          these bubble up to state and national levels, where they are
          implemented at large scales.
        </p>
        <p>
          <b>
            Let’s use our resources, engage our people, and leverage our
            political will to achieve truly exciting goals that effectively
            serve our residents.
          </b>{" "}
          Things don’t have to be business as usual. This is our chance to lead.
        </p>
      </div>
    ),
  },
];
